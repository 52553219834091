import HttpService from "./HttpService";

export default class TrackingService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get TrackingOrder
   * @returns {Promise}
   */
  getTrackingOrder(tracking_number, recaptcha_token) {
    const data = {
      tracking_number: tracking_number,
      recaptcha_token: recaptcha_token,
    };
    return this.httpService.post("/v1/orders/show_tracking/", data);
  }
}
